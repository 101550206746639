<template>
  <b-col
    :md="cols"
    class="d-md-flex align-items-baseline justify-content-end"
    style="gap:20px;"
  >
    <div>
      <b-form-group
        :label="$t('student-report-module.summary-table.from')"
      >
        <b-form-datepicker
          v-model="filter.from"
          :placeholder="$t('higher-level-report-module.no-date-selected')"
          :max="maxDate"
          @input="getDate()"
        />
      </b-form-group>
    </div>
    <div>
      <b-form-group
        :label="$t('student-report-module.summary-table.to')"
      >
        <b-form-datepicker
          v-model="filter.to"
          :placeholder="$t('higher-level-report-module.no-date-selected')"
          :max="maxDate"
          @input="getDate()"
        />
      </b-form-group>
    </div>
    <div>
      <b-dropdown
        :text="$t('higher-level-report-module.time-range')"
        variant="outline-primary"
      >
        <b-dropdown-item
          href="#"
          @click="getDate('week')"
        >
          {{ $t('higher-level-report-module.this-week') }}
        </b-dropdown-item>
        <b-dropdown-item
          href="#"
          @click="getDate('month')"
        >
          {{ $t('higher-level-report-module.this-month') }}
        </b-dropdown-item>
        <b-dropdown-item
          href="#"
          @click="getDate('all')"
        >
          {{ $t('higher-level-report-module.all-time') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </b-col>
</template>
<script>
import {
  BCol, BFormGroup, BFormDatepicker, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCol,
    BFormGroup,
    BFormDatepicker,
    BDropdown,
    BDropdownItem,
  },
  props: {
    cols: {
      type: String,
      default: '12',
    },
    maxDate: {
      type: [String, Object],
      default: moment().format('YYYY-MM-DD'),
    },
  },
  data() {
    return {
      filter: {
        from: null,
        to: null,
      },
    }
  },
  methods: {
    getDate(type = '') {
      if (type === 'month') {
        this.filter = {
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().endOf('month').format('YYYY-MM-DD'),
        }
      } if (type === 'week') {
        this.filter = {
          from: moment().startOf('week').format('YYYY-MM-DD'),
          to: moment().endOf('week').format('YYYY-MM-DD'),
        }
      } if (type === 'all') {
        this.filter = {
          from: null,
          to: null,
        }
      }
      this.$emit('getFilteredDate', this.filter)
    },
  },
}
</script>
