<template>
  <div>
    <b-row
      v-if="classDetails && $route.params.courseId !== 'course'"
    >
      <b-col md="12">
        <div class="pb-1">
          <b-button
            variant="flat-secondary"
            class="btn-icon"
            @click="$router.push({ name: `${self.usertype}-view-students`, params: {id: classId} })"
          >
            <feather-icon
              size="20"
              icon="ArrowLeftIcon"
            />
          </b-button>
          <span>
            <span class="text-capitalize">{{ classDetails.class_room }}</span>
          </span>
        </div>
      </b-col>
      <!-- <b-col md="12">
        <class-efficiency />
      </b-col> -->
      <b-col md="12">
        <students-table
          :students="students"
          :class-details="classDetails"
          :show-rubric="isTeacherOfClass"
          @showStat="showStudentLessonStats"
          @showChart="showStudentEffeciency"
          @navigateToTeacherCalendar="navigateToTeacherCalendar"
          @navigateToStudentCalendar="navigateToStudentCalendar"
          @onViewStudentRubric="(ind)=>indexOfRubricBeingViewed=ind"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <div class="pb-1">
          <b-button
            variant="flat-secondary"
            class="btn-icon"
            @click="$router.push({name: `${self.usertype}-view-students`, params: {id: classId} })"
          >
            <feather-icon
              size="20"
              icon="ArrowLeftIcon"
            />
          </b-button>

          <span>
            {{ $t('actions.back') }}
          </span>
        </div>
      </b-col>
    </b-row>
    <b-tabs
      v-if="isHighLevelUser"
      v-model="activeTab"
      pills
    >
      <b-tab :title="$t('course-completion-module.course-report')"
             lazy
      >
        <b-row>
          <b-col>
            <report-view @showStat="showStudentLessonStats" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        v-if="!hideAcademicReport"
        :title="$t('course-completion-module.academic-skills')"
        lazy
      >
        <b-row>
          <b-col>
            <academic-group-report
              :academic-report="academicReport"
              :class-room="{ id: classId }"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        v-if="!hideLanguageSkillReport"
        :title="$t('course-completion-module.language-skills')"
        lazy
      >
        <b-row>
          <b-col>
            <group-report
              :class-room="{ id: classId }"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        v-if="!hideStudySkillsReport"
        :title="$t('course-completion-module.study-skills')"
        lazy
      >
        <b-row>
          <b-col>
            <study-group-report
              :study-skills-report="studySkillsReport"
              :class-room="{ id: classId }"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        v-if="!hideSocialSkillsReport"
        :title="$t('course-completion-module.social-skills')"
        lazy
      >
        <b-row>
          <b-col>
            <social-group-report
              :social-skills-report="socialSkillsReport"
              :class-room="{ id: classId }"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        v-if="isHighLevelUser && !hideGameReport"
        :title="$t('course-completion-module.game-report')"
        lazy
      >
        <game-report :class-room="{ id: classId }" />
      </b-tab>
      <b-tab
        v-if="isHighLevelUser && !hideClassTestReport"
        :title="$t('course-completion-module.class-test-report')"
        lazy
      >
        <test-report
          :class-room="{ id: classId }"
        />
      </b-tab>
    </b-tabs>
    <div v-else>
      <report-view @showStat="showStudentLessonStats" />
    </div>
    <lesson-notes-modal
      :show="viewingNotesOfLesson!=null"
      :lesson-id="viewingNotesOfLesson"
      :student-id="activeStudentId"
      @close="viewingNotesOfLesson=null"
    />
    <lesson-essay-modal
      :show="viewingEssayOfLesson!=null && activeStudentId!=null"
      :lesson-id="viewingEssayOfLesson"
      :student-id="activeStudentId"
      @close="()=>{viewingEssayOfLesson=null;activeStudentId=null}"
    />

    <div class="dialogs">
      <dialog-drag
        v-for="dlg in dialogs"
        :id="dlg.id"
        :key="dlg.id"
        :ref="&quot;dlg-&quot; + dlg.id"
        :class="dlg.type"
        :options="dlg.options"
        :style="`z-index:${activeDialogs.indexOf(dlg.id)==-1 ? '100':100+(5-activeDialogs.indexOf(dlg.id)).toString()} !important`"
        @close="removeDialog"
        @drag-end="dialogDragEnd"
        @drag-start="selectDialog"
        @move="dialogDragEnd"
      >
        <h5
          slot="title"
          class="m-0 text-capitalize"
          @click="setActiveDialog(dlg.id)"
        >{{ dlg.name }}
        </h5>
        <student-lessons-report
          v-if="dlg.type === DIALOG_TYPE_STUDENT_LESSONS_REPORT"
          :index="dlg.index"
          :lessons="students[dlg.index]['lessons'] || []"
          :student-id="students[dlg.index]['student_id']"
          :is-processing="isProcessing"
          @showStat="showStudentLessonProblemsStat"
          @showLessonNotes="(id,sId)=>{viewingNotesOfLesson=id;activeStudentId=sId}"
          @showLessonEssay="(id,sId)=>{viewingEssayOfLesson=id;activeStudentId=sId}"
        />
        <student-problems-report
          v-else-if="dlg.type === DIALOG_TYPE_STUDENT_LESSON_REPORT"
          :index="dlg.index"
          :problem="students[dlg.parentIndex]['lessons'][dlg.index]['problems'] || {}"
          :is-processing="isProcessing"
          :dialog-name="dlg.name"
          @showStat="showStudentLessonProblemsStat"
          @showPreview="showProblemPreview"
        />
        <student-efficiency
          v-else-if="dlg.type === DIALOG_TYPE_STUDENT_EFFICIENCY"
        />
      </dialog-drag>

    </div>

    <question-preview
      :show="showPreview"
      :data="problemBeingPreviewed ? problemPreview[problemBeingPreviewed.problem_id] :{}"
      @close="showPreview=false"
    />
  </div>

</template>
<script>

import {
  BRow, BCol, BButton, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DialogDrag from 'vue-dialog-drag'
import i18n from '@/libs/i18n'
import { getUserData, isHighLevelUser } from '@/auth/utils'
import { engagementSettings } from '@/utils/visibilitySettings'
import useJwt from '@/auth/jwt/useJwt'
import { USER_TYPE_TEACHER } from '@/const/userType'
import QuestionPreview from '@/views/common/components/QuestionPreview.vue'
import LessonNotesModal from '@views/student/note/LessonNotesModal.vue'
import GroupReport from '@/views/v2/common/class/components/GroupReport.vue'
import AcademicGroupReport from '@/views/v2/common/class/components/AcademicGroupReport.vue'
import SocialGroupReport from '@/views/v2/common/class/components/SocialGroupReport.vue'
import StudyGroupReport from '@/views/v2/common/class/components/StudyGroupReport.vue'
import StudentsTable from './components/student/StudentsTable.vue'
import '@/assets/scss/custom/draggableModal.scss'
import StudentLessonsReport from './components/LessonsReport.vue'
import StudentProblemsReport from './components/ProblemsReport.vue'
import LessonEssayModal from './components/LessonEssayModal.vue'
import StudentEfficiency from './components/student/StudentEfficiency.vue'
import ReportView from './report/Index.vue'
import TestReport from './test-report/index.vue'
import GameReport from './game-report/index.vue'

export default ({
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    BCol,
    BButton,
    StudentsTable,
    DialogDrag,
    ReportView,
    LessonEssayModal,
    StudentLessonsReport,
    StudentProblemsReport,
    StudentEfficiency,
    QuestionPreview,
    LessonNotesModal,
    BTab,
    BTabs,
    GroupReport,
    TestReport,
    GameReport,
    AcademicGroupReport,
    SocialGroupReport,
    StudyGroupReport,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      classId: null,
      dialogs: [],
      activeDialogs: [],
      self: getUserData(),
      DIALOG_TYPE_STUDENT_LESSONS_REPORT: 'student-lessons-report',
      DIALOG_TYPE_STUDENT_EFFICIENCY: 'student-efficiency',
      DIALOG_TYPE_STUDENT_LESSON_REPORT: 'student-problems-report',
      style: { name: 'dialog-1', options: { width: 1000 } },
      classDetails: null,
      isProcessing: false,
      students: [],
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      indexOfRubricBeingViewed: null,
      problemBeingPreviewed: null,
      problemTemplates: {},
      problemPreview: {},
      showPreview: false,
      userId: getUserData().id,
      viewingNotesOfLesson: null,
      viewingEssayOfLesson: null,
      activeStudentId: null,
      activeTab: localStorage.getItem('active-tab-for-report'),
      hideClassTestReport: 0,
      hideGameReport: 0,
      hideLanguageSkillReport: 0,
      academicReport: {
        problemSolving: 0,
        proficiencyOnLessons: 0,
        lessonCompletion: 0,
      },
      studySkillsReport: {
        attendance: 0,
        activity: 0,
        schedule: 0,
      },
      socialSkillsReport: {
        note: 0,
        chat: 0,
        role: 0,
      },
    }
  },
  computed: {
    isHighLevelUser() {
      return isHighLevelUser() || this.isATeacher
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    isTeacherOfClass() {
      return this.isATeacher && this.classDetails?.teacher === this.self.id
    },
    hideAcademicReport() {
      return this.academicReport.problemSolving === 1
         && this.academicReport.proficiencyOnLessons === 1
         && this.academicReport.lessonCompletion === 1 ? 1 : 0
    },
    hideStudySkillsReport() {
      return this.studySkillsReport.attendance === 1
         && this.studySkillsReport.activity === 1
         && this.studySkillsReport.schedule === 1 ? 1 : 0
    },
    hideSocialSkillsReport() {
      return this.socialSkillsReport.note === 1
         && this.socialSkillsReport.chat === 1
         && this.socialSkillsReport.role === 1 ? 1 : 0
    },
  },
  watch: {
    '$route.query.user_id': {
      handler(id) {
        if (id) this.userId = id
      },
      deep: true,
      immediate: true,
      totalTime: '',
    },
    $route(to, from) {
      if (to !== from) {
        this.initiate()
      }
    },
    activeTab(newValue) {
      localStorage.setItem('active-tab-for-report', newValue)
    },
  },
  beforeDestroy() {
    localStorage.removeItem('active-tab-for-report')
  },

  created() {
    this.initiate()
    this.getClassRoomSettings()
  },
  methods: {
    initiate() {
      this.classId = parseInt(this.$route.params.id, 10)
      // this.getClassStats()
    },
    showProblemPreview(prob) {
      this.problemBeingPreviewed = prob
      this.getProblemForPreview()
    },
    getTemplates(id) {
      if (!id) return
      if (this.problemTemplates[id]) return
      // eslint-disable-next-line consistent-return
      return new Promise(resolve => {
        useJwt.getTemplate(id).then(res => {
          this.problemTemplates[id] = res.data.data
          resolve('')
        })
      })
    },
    getTabIndex(level) {
      const levels = ['A1', 'A2', 'B1', 'B2']
      return levels.indexOf(level)
    },
    async getProblemForPreview() {
      if (this.problemPreview[this.problemBeingPreviewed.problem_id]) {
        this.showPreview = true
      } else {
        useJwt.getProblem(this.problemBeingPreviewed.problem_id).then(async res => {
          const problemData = res.data.data
          const index = this.getTabIndex(this.problemBeingPreviewed.lang_level)
          const statementAndFeedback = problemData.problem_statements[index]

          await this.getTemplates(statementAndFeedback.fb_template_id)
          await this.getTemplates(statementAndFeedback.st_template_id)

          const answers = await useJwt.getAnswerPreview(this.problemBeingPreviewed.test_id)

          this.problemPreview[this.problemBeingPreviewed.problem_id] = {
            theme: problemData.theme ? problemData.theme : 0,
            problemType: problemData.problem_type.name,
            feedback: {
              type: statementAndFeedback.fb_type,
              text: statementAndFeedback.feedback,
              image: statementAndFeedback.fb_type === 'image' ? statementAndFeedback.fb_image : null,
              audio: statementAndFeedback.fb_type === 'audio' ? statementAndFeedback.fb_image : null,
              template: this.problemTemplates[statementAndFeedback.fb_template_id],
            },
            statement: {
              type: statementAndFeedback.st_type,
              text: statementAndFeedback.statement,
              image: statementAndFeedback.st_type === 'image' ? statementAndFeedback.st_image : null,
              audio: statementAndFeedback.st_type === 'audio' ? statementAndFeedback.st_image : null,
              template: this.problemTemplates[statementAndFeedback.st_template_id],
              video: problemData.video_time_stamp,
            },
            questions: problemData.questions.filter(e => e.lang_level === (index + 1).toString()),
            answers: answers.data?.data,
            level: index + 1,
          }
          this.showPreview = true
        })
      }
    },
    getClassStats() {
      if (this.$route.params.courseId && this.$route.params.courseId !== 'null' && this.$route.params.courseId !== 'course') {
        useJwt.getReportsForCourseAndClass(this.classId, this.$route.params.courseId).then(res => {
          this.classDetails = res.data.data
          this.students = this.classDetails.student
          this.students.forEach(e => {
            e.total_lessons = this.classDetails.total_lesson
            e.total_problems = this.classDetails.total_problems
          })
        }).finally(() => {
          this.isProcessing = false
        })
      } else {
        useJwt.getClassStats(this.classId, this.userId === this.self.id ? null : this.userId).then(res => {
          this.classDetails = res.data.data
          this.students = this.classDetails.students
          this.students.forEach(e => {
            e.total_lessons = this.classDetails.total_lesson
            e.total_problems = this.classDetails.total_problems
          })
        }).finally(() => {
          this.isProcessing = false
        })
      }
    },
    getStudentStats(studentIndex) {
      this.isProcessing = true
      useJwt.getStudentStats(this.classId, this.students[studentIndex].student_id).then(res => {
        this.students[studentIndex].lessons = res.data.data
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getStudentLessonStats(studentIndex, lessonIndex) {
      const student = this.students[studentIndex]
      this.isProcessing = true
      useJwt.getStudentLessonStats(this.classId, student.student_id, student.lessons[lessonIndex].lesson_id).then(res => {
        this.students[studentIndex].lessons[lessonIndex].problems = res.data.data
      }).finally(() => {
        this.isProcessing = false
      })
    },
    setActiveDialog(e) {
      this.activeDialogs.unshift(e)
      if (this.activeDialogs.length > 5) this.activeDialogs.length = 5
    },
    rndText() {
      return Math.random().toString(36).substring(7)
    },
    drop(id) {
      const index = this.findDialog(id)
      if (index !== null) {
        this.droppeds.push(this.dialogs[index])
        this.dialogs.splice(index, 1)
      }
    },
    unDrop(id) {
      const index = this.findDialog(id, this.droppeds)
      if (index !== null) {
        this.dialogs.push(this.droppeds[index])
        this.droppeds.splice(index, 1)
      }
    },
    showStudentLessonStats(index) {
      const student = this.students[index]
      if (!('lessons' in student)) {
        this.getStudentStats(index)
      }
      const id = `student-lessons-report-${index}`
      if (this.dialogs.findIndex(e => e.id === id) === -1) {
        this.dialogs.push(this.dialog(
          {
            id,
            index,
            name: student.name,
            type: this.DIALOG_TYPE_STUDENT_LESSONS_REPORT,
          },
        ))
      }
      this.setActiveDialog(id)
      return this.dialogs
    },
    showStudentEffeciency(index) {
      const student = this.students[index]
      const id = `student-efficiency-${index}`
      if (this.dialogs.findIndex(e => e.id === id) === -1) {
        this.dialogs.push(this.dialog(
          {
            id,
            index,
            name: student.name,
            type: this.DIALOG_TYPE_STUDENT_EFFICIENCY,
          },
        ))
      }
      this.setActiveDialog(id)
      return this.dialogs
    },
    showStudentLessonProblemsStat(studentIndex, lessonIndex) {
      const student = this.students[studentIndex]
      const lesson = student.lessons[lessonIndex]
      if (!('problems' in lesson)) {
        this.getStudentLessonStats(studentIndex, lessonIndex)
      }
      const id = `student-problems-report-${studentIndex}-${lessonIndex}`
      if (this.dialogs.findIndex(e => e.id === id) === -1) {
        this.dialogs.push(this.dialog(
          {
            id,
            index: lessonIndex,
            parentIndex: studentIndex,
            name: `${student.name} ${lesson.lesson} ${i18n.tc('student-report-module.report')} `,
            type: this.DIALOG_TYPE_STUDENT_LESSON_REPORT,
          },
        ))
      }
      this.setActiveDialog(id)
      return this.dialogs
    },
    removeDialog(dialog) {
      const { id } = dialog
      const index = this.findDialog(id)
      this.dialogs.splice(index, 1)
      if (this.activeDialogs[0] === id) this.activeDialogs.shift()
    },
    findDialog(id, dialogs) {
      // eslint-disable-next-line no-param-reassign
      if (!dialogs) dialogs = this.dialogs
      const index = dialogs.findIndex(val => val.id === id)
      return (index > -1) ? index : null
    },
    dialog(data) {
      const content = this.rndText()
      let options = {}
      const style = this
      options = { ...style.options }
      if (!options.left) options.left = 30 * this.dialogs.length
      if (!options.top) options.top = (this.windowHeight / 2) + 30 * this.dialogs.length
      return {
        ...data,
        ...{
          content, style, options,
        },
      }
    },
    dialogDragEnd(obj) {
      const index = this.findDialog(obj.id)
      this.$set(this.dialogs[index].options, 'left', obj.left)
      this.$set(this.dialogs[index].options, 'top', obj.top)
    },
    selectDialog(obj) {
      const index = this.findDialog(obj.id)
      this.setActiveDialog(this.dialogs[index].id)
    },

    navigateToTeacherCalendar(studentId) {
      this.$router.push({ name: 'teacher-student-calendar', params: { classId: this.classId, studentId } })
    },

    navigateToStudentCalendar() {
      this.$router.push({ name: 'student-calendar', params: { classId: this.classId } })
    },
    getClassRoomSettings() {
      useJwt.getClassRoomSettings(this.classId).then(res => {
        const data = res.data?.data
        const districtSettings = res.data?.district_settings

        // check for district and school level settings
        // language skills report
        const schoolLanguageSkillsReport = +data?.find(item => item.setting_name === 'language_skills_report')?.value || 0
        const districtLanguageSkillsReport = +districtSettings?.find(item => item.setting_name === 'language_skills_report')?.value || 0
        this.hideLanguageSkillReport = districtLanguageSkillsReport === 1 || schoolLanguageSkillsReport === 1 ? 1 : 0

        // game report
        const schoolGameReport = +data?.find(item => item.setting_name === 'game_report')?.value || 0
        const districtGameReport = +districtSettings?.find(item => item.setting_name === 'game_report')?.value || 0
        this.hideGameReport = districtGameReport === 1 || schoolGameReport === 1 ? 1 : 0

        // class test report
        const schoolClassTestReport = +data?.find(item => item.setting_name === 'class_test_report')?.value || 0
        const districtClassTestReport = +districtSettings?.find(item => item.setting_name === 'class_test_report')?.value || 0
        this.hideClassTestReport = districtClassTestReport === 1 || schoolClassTestReport === 1 ? 1 : 0

        // problem solving skills
        const schoolProblemSolvingSkills = +data?.find(item => item.setting_name === 'problem_solving_skills')?.value || 0
        const districtProblemSolvingSkills = +districtSettings?.find(item => item.setting_name === 'problem_solving_skills')?.value || 0
        const problemSolvingSkillsValue = districtProblemSolvingSkills === 1 || schoolProblemSolvingSkills === 1 ? 1 : 0

        // proficiency on lessons
        const schoolProficiencyOnLessons = +data?.find(item => item.setting_name === 'proficiency_on_lessons')?.value || 0
        const districtProficiencyOnLessons = +districtSettings?.find(item => item.setting_name === 'proficiency_on_lessons')?.value || 0
        const proficiencyOnLessonsValue = districtProficiencyOnLessons === 1 || schoolProficiencyOnLessons === 1 ? 1 : 0

        // lesson completion rate
        const schoolLessonCompletionRate = +data?.find(item => item.setting_name === 'lesson_completion_rate')?.value || 0
        const districtLessonCompletionRate = +districtSettings?.find(item => item.setting_name === 'lesson_completion_rate')?.value || 0
        const lessonCompletionRateValue = districtLessonCompletionRate === 1 || schoolLessonCompletionRate === 1 ? 1 : 0

        this.academicReport = {
          problemSolving: problemSolvingSkillsValue,
          proficiencyOnLessons: proficiencyOnLessonsValue,
          lessonCompletion: lessonCompletionRateValue,
        }

        // attendance report
        const schoolAttendanceReport = +data?.find(item => item.setting_name === 'attendance_report')?.value || 0
        const districtAttendanceReport = +districtSettings?.find(item => item.setting_name === 'attendance_report')?.value || 0
        const attendanceReportValue = districtAttendanceReport === 1 || schoolAttendanceReport === 1 ? 1 : 0

        // activity report
        const schoolActivityReport = +data?.find(item => item.setting_name === 'activity_report')?.value || 0
        const districtActivityReport = +districtSettings?.find(item => item.setting_name === 'activity_report')?.value || 0
        const activityReportValue = districtActivityReport === 1 || schoolActivityReport === 1 ? 1 : 0

        // schedule_report
        const schoolScheduleReport = +data?.find(item => item.setting_name === 'schedule_report')?.value || 0
        const districtScheduleReport = +districtSettings?.find(item => item.setting_name === 'schedule_report')?.value || 0
        const scheduleReportValue = districtScheduleReport === 1 || schoolScheduleReport === 1 ? 1 : 0

        this.studySkillsReport = {
          attendance: attendanceReportValue,
          activity: activityReportValue,
          schedule: scheduleReportValue,
        }

        // note_report
        const schoolNoteReport = +data?.find(item => item.setting_name === 'note_report')?.value || 0
        const districtNoteReport = +districtSettings?.find(item => item.setting_name === 'note_report')?.value || 0
        const noteReportValue = districtNoteReport === 1 || schoolNoteReport === 1 ? 1 : 0

        // chat_report
        const schoolChatReport = +data?.find(item => item.setting_name === 'chat_report')?.value || 0
        const districtChatReport = +districtSettings?.find(item => item.setting_name === 'chat_report')?.value || 0
        const chatReportValue = districtChatReport === 1 || schoolChatReport === 1 ? 1 : 0

        // role_report
        const schoolRoleReport = +data?.find(item => item.setting_name === 'role_report')?.value || 0
        const districtRoleReport = +districtSettings?.find(item => item.setting_name === 'role_report')?.value || 0
        const roleReportValue = districtRoleReport === 1 || schoolRoleReport === 1 ? 1 : 0

        this.socialSkillsReport = {
          note: noteReportValue,
          chat: chatReportValue,
          role: roleReportValue,
        }

        // engagement settings
        const engagementKeys = ['game', 'avatar', 'attention']
        const engagementSetting = engagementSettings(engagementKeys, data, districtSettings)
        let settings = data.filter(setting => !engagementKeys.includes(setting.setting_name))
        settings.push(...engagementSetting)

        // AI settings
        const aiKeys = ['has_chat_bot', 'ai_speech', 'ai_feedback']
        const aiEnabledByDistrict = districtSettings.find(setting => setting.setting_name === 'open_ai')?.value ?? 1
        const aiEnabledBySchool = data.find(setting => setting.setting_name === 'open_ai')?.value ?? 1
        let aiSettings = []

        if (aiEnabledByDistrict && aiEnabledBySchool) {
          aiSettings = engagementSettings(aiKeys, data, districtSettings)
        }
        settings = data.filter(setting => !aiKeys.includes(setting?.setting_name))
        settings.push(...aiSettings)

        this.$store.dispatch('session/setClassSettings', { settings, selfStudyClassRoom: false })
      })
    },
  },

})
</script>
<style src='vue-dialog-drag/dist/vue-dialog-drag.css'></style>

<style>
#question-preview-modal___BV_modal_outer_{
  z-index: 1000000000 !important;
}
.main-menu{
  z-index: 500;
}
</style>
