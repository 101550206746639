<template>
  <b-overlay>
    <b-card style="overflow:hidden;">
      <b-row>
        <b-col cols="9"
               class="light pb-1 lead font-weight-bold"
        >
          {{ $t('setting-module.proficiency-on-lessons-title') }}
        </b-col>
        <b-col
          cols="3"
          class="text-right"
        >

          <b-button
            variant="primary"
            @click="exportExcel()"
          >
            {{ $t('student-report-module.report-pages.export-excel') }}
            <feather-icon icon="FileTextIcon" />
          </b-button>

        </b-col>
      </b-row>

      <div class="information border mt-1 p-1">
        <p>
          <b-badge class="bg-danger round">
            3
          </b-badge>
          {{ $t('student-report-module.report-pages.number-to-complete-lesson') }}
        </p>
        <p class="">
          {{ $t('student-report-module.report-pages.note-info') }}
        </p>
        <div class="d-flex align-items-start"
             style="gap:15px;"
        >
          <h6>{{ $t('student-report-module.report-pages.indicators') }}</h6>
          <p><b-badge pills
                      variant="danger"
          >
            {{ $t('red') }}:
          </b-badge>{{ $t('student-report-module.report-pages.color-indicator.red') }}</p>
          <p><b-badge pills
                      variant="success"
          >
            {{ $t('green') }}:
          </b-badge>{{ $t('student-report-module.report-pages.color-indicator.green') }}</p>
          <p><b-badge pills
                      variant="warning"
          >
            {{ $t('orange') }}:
          </b-badge>{{ $t('student-report-module.report-pages.color-indicator.orange') }}</p>
          <p><b-badge pills
                      variant="secondary"
          >
            {{ $t('gray') }}:
          </b-badge> {{ $t('student-report-module.report-pages.color-indicator.gray') }}</p>
        </div>
      </div>
      <b-card body-class="p-0 table-responsive mb-0 overflow-hidden">

        <b-row class="py-2 pl-1 d-flex"
               style="gap:20px;"
        >
          <div>
            <label>
              <b>
                {{ $t('student-report-module.report-pages.filter') }} :
              </b>
            </label>
          </div>

          <div
            v-for="filter in filters"
            :key="filter"
            class="form-check"
          >
            <input
              id="flexRadioDefault1"
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              :checked="filter === activeFilter"
              @change="filterReport(filter)"
            >
            <label
              class="form-check-label"
              for="flexRadioDefault1"
            >
              <!-- {{ snakeToTitleCase(filter) }} -->

              {{ $t(`student-report-module.report-pages.filter-type.${filter}`) }}
            </label>
          </div>

        </b-row>

        <b-table-simple
          class="table table-hover proficiency-table table-striped table-bordered table-sm"
          sticky-header="800px"
          style="overflow:scroll !important;"
        >
          <b-thead>
            <b-tr>
              <b-th>
                <span class="vertical-text">
                  {{ $t('actions.lessons') }}
                </span>
                <span style="position:absolute; bottom:0;">
                  {{ $t('student-report-module.report-pages.no-of-lesson-completed') }}
                  <feather-icon icon="ArrowRightIcon" />
                </span>
              </b-th>
              <b-th
                v-for="student of studentLists"
                :key="student.user_id"
              >
                <div
                  class="vertical-text"
                  style="margin-bottom:25px"
                >
                  {{ student.full_name }}
                </div>
                <div style="position:absolute;bottom:0;">
                  {{ getTotalLessonDone(student.user_id) }}
                </div>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-if="activeFilter === 'course'">
              <template v-for="(reportLists, index) of groupByKey(filteredLessonScoreReport, 'course_id')">
                <b-tr
                  :key="index"
                  class="bg-secondary text-white"
                >
                  <b-td
                    style="min-width: 250px;"
                    class="text-right"
                    colspan="100%"
                  >
                    <b>
                      {{ reportLists[0].course_name }}
                    </b>
                  </b-td>
                  <!-- <b-td :colspan="studentLists.length">
                    <b>{{ reportLists[0].score_percentage }}</b>
                  </b-td> -->
                </b-tr>
                <template v-for="(report, i) of groupByKey(reportLists, 'lesson_id')">
                  <score-lesson :key="i"
                                :report="report"
                                :get-row-color="getRowColor"
                                :student-lists="studentLists"
                                :get-table-class="getTableClass"
                                :get-level-from-score="getLevelFromScore"
                                :get-tool-tip-content="getToolTipContent"
                                :active-filter="activeFilter"
                  />
                </template>
              </template>
            </template>
            <template v-else>
              <template
                v-for="(report, index) of groupByKey(filteredLessonScoreReport, 'lesson_id')"
              >
                <score-lesson :key="index"
                              :report="report"
                              :get-row-color="getRowColor"
                              :student-lists="studentLists"
                              :get-table-class="getTableClass"
                              :get-level-from-score="getLevelFromScore"
                              :get-tool-tip-content="getToolTipContent"
                              :active-filter="activeFilter"
                />
              </template>
            </template>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-card>

  </b-overlay>

</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard, BOverlay, BTableSimple, BThead, BTh, BTr, BTd, BTbody, VBTooltip, BBadge, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { getLevelFromScore } from '@/const/langLevel'
import exportFromJSON from 'export-from-json'
import ScoreLesson from './helper/ScoreLesson.vue'

export default {
  components: {
    BCard,
    BOverlay,
    BTableSimple,
    BTd,
    BTbody,
    BThead,
    BTh,
    BTr,
    BBadge,
    BButton,
    BRow,
    BCol,
    ScoreLesson,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      lessonScoreReport: [],
      isProcessing: false,
      studentLists: [],
      // filters: ['cefr_rate', 'pass_score', 'language_level', 'course', 'completed', 'sequenced_or_non_sequenced'],
      filters: ['cefr_rate', 'pass_score', 'language_level', 'completed', 'sequenced', 'non-sequenced', 'course'],
      activeFilter: 'pass_score',
      json_data: [],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },
  computed: {
    filteredLessonScoreReport() {
      if (this.activeFilter === 'completed') {
        return this.lessonScoreReport.filter(item => item.completed_at)
      }
      if (['sequenced', 'non-sequenced'].includes(this.activeFilter)) {
        return this.lessonScoreReport.filter(item => {
          if (this.activeFilter === 'sequenced') {
            return !item.is_non_sequence
          }
          return item.is_non_sequence
        })
      }
      return this.lessonScoreReport
    },
  },
  watch: {
    activeFilter(newValue, oldValue) {
      if ([newValue, oldValue].find(item => ['sequenced', 'non-sequenced', 'course'].includes(item))) {
        this.lessonScoreReport = []
        this.getLessonScoreReport()
      }
    },
  },
  mounted() {
    this.getLessonScoreReport()
  },
  methods: {
    async getLessonScoreReport() {
      await useJwt.getLessonScoreReport(this.classRoom.id, {
        params: {
          filter: this.activeFilter,
        },
      }).then(response => {
        this.lessonScoreReport = response.data.data
        const students = []
        response.data.data.forEach(data => {
          if (!students.find(student => student.user_id === data.user_id)) {
            students.push({
              user_id: data.user_id,
              full_name: `${data.firstname} ${data.lastname}`,
            })
          }
        })
        this.studentLists = students
        this.getReportForExcel()
      })
    },
    getTableClass(lessonInfo) {
      const status = lessonInfo?.status

      if (status === 'passed') {
        return 'bg-success'
      }
      if (status === 'failed' || (!lessonInfo?.completed_at && lessonInfo?.round > 2)) {
        return 'bg-danger'
      }

      if ((status === 'in_progress' || status === 'assessment' || !lessonInfo?.completed_at) && ((lessonInfo?.total_passed_count && lessonInfo?.total_passed_count > 0) || lessonInfo?.round > 0)) {
        return 'bg-warning'
      }

      return 'bg-secondary'

      // if (!lessonInfo) return 'bg-secondary'
      // if (Number(lessonInfo.score_percentage) >= 75 && lessonInfo.completed_at) return 'bg-success'
      // if (Number(lessonInfo.score_percentage) < 75 && lessonInfo.completed_at) return 'bg-danger'

      // if (Number(lessonInfo.score_percentage) > 0 && !lessonInfo.completed_at) return 'bg-warning'
      // return 'bg-secondary'
    },
    getRowColor(report) {
      if (report.every(r => Number(r.score_percentage) < 75)) {
        return { background: 'cyan' }
      }
      if (report.every(r => Number(r.score_percentage) >= 75 && Number(r.score_percentage) < 100)) {
        return { background: '#fff3db' }
      }
      return ''
    },
    getTotalLessonDone(userId) {
      return this.filteredLessonScoreReport.filter(r => r.user_id === userId && r.completed_at).length
    },
    getToolTipContent(lessonInfo) {
      if ((lessonInfo && lessonInfo?.total_passed_count > 0) || lessonInfo?.round > 0) {
        return `<div class="text-left">
          <ul>
              <li><b>${this.$i18n.tc('no-of-attempts')}:</b> ${lessonInfo.round}</li>
              <li><b>${this.$i18n.tc('points')}:</b> ${lessonInfo.points}</li>
              <li><b>${this.$i18n.tc('cefr-rate')}:</b> ${lessonInfo.cefr_rate}</li>
              <li><b>${this.$i18n.tc('problems-passed')}:</b> ${lessonInfo.total_passed_count}/${lessonInfo.total_problems_count}</li>
              <li><b>${this.$i18n.tc('engine')}:</b> ${lessonInfo.engine}</li>
              <li><b>${this.$i18n.tc('status')}: </b> ${lessonInfo.status}</li>
            </ul>
          </div>`
      }
      return `<p> ${this.$i18n.tc('not-started-yet')} </p>`
    },
    filterReport(filter) {
      this.activeFilter = filter
      if (this.activeFilter === 'pass_score') this.getReportForExcel('score_percentage')
      if (this.activeFilter === 'cefr_rate') this.getReportForExcel('cefr_rate')
      if (this.activeFilter === 'language_level') this.getReportForExcel('language_level')
      if (this.activeFilter === 'completed') {
        this.getReportForExcel('completed')
      }
    },
    getReportForExcel(filter = 'score_percentage') {
      this.isProcessing = true
      const lessonScoreReport = this.groupByKey(this.filteredLessonScoreReport, 'lesson_id')
      this.json_data = []
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const reportKey in lessonScoreReport) {
        const newobject = {}
        const reports = lessonScoreReport[reportKey]
        reports.forEach(report => {
          if (filter === 'language_level') {
            newobject[report.firstname + report.lastname] = this.getLevelFromScore(report.cefr_rate)
          } else {
            newobject[report.firstname + report.lastname] = report[filter]
          }
        })
        this.json_data.push({
          'Lesson Name': reports ? reports[0].lesson_name : '',
          ...newobject,
        })
      }
      this.isProcessing = false
    },
    exportExcel() {
      const data = this.json_data
      const fileName = this.activeFilter
      const exportType = 'csv'

      exportFromJSON({ data, fileName, exportType })
    },
    getLevelFromScore,
  },
}
</script>

<style scoped lang="scss">
  .vertical-text {
    writing-mode: tb-rl;
    -webkit-transform: rotate(180deg);
    text-align: center;
    vertical-align: middle;
  }

  label{
    font-size: 14px;

  }

</style>
