<template>
  <b-overlay>
    <b-card>
      <b-row>
        <div class="light col-9  pb-1 lead font-weight-bold">
          {{ $t('setting-module.problem-solving-skills-of-students') }}
        </div>
        <b-col
          cols="3"
          class="text-right"
        >
          <b-button
            variant="primary"
            data-html2canvas-ignore="true"
            @click="exportPdf()"
          >
            {{ $t('student-report-module.report-pages.export-pdf') }}
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </b-col>
      </b-row>
      <div>
        <b-row id="report-card">
          <b-col
            md="12"
            class="p-1"
          >
            <vue-apex-charts
              type="bar"
              :options="options"
              :series="series"
            />
          </b-col>
          <b-col
            id="report-table"
            md="12"
            class="p-1"
          >
            <div class="mt-2 mb-2">
              <b-row>
                <b-col md="3">
                  <h5> {{ $t('student-report-module.summary') }}</h5>
                </b-col>
                <DateFilter
                  :cols="9"
                  @getFilteredDate="getProblemSolvingByDate"
                />
              </b-row>
              <strength-table
                class="mt-2"
                :title="$t('student-report-module.report-pages.skill')"
                :summary="getMinMax(scoreReport, 'score_percentage')"
              />
            </div>
            <b-card body-class="p-0 table-responsive mb-0">
              <table class="table table-hover table-striped table-bordered table-sm">
                <thead>
                  <tr>
                    <th>
                      {{ $t('student-report-module.report-pages.student-name') }}
                    </th>
                    <th>
                      {{ $t('student-report-module.report-pages.total-passed') }}
                    </th>
                    <th>
                      {{ $t('student-report-module.report-pages.total-problems') }}
                    </th>
                    <th>
                      {{ $t('student-report-module.report-pages.score-percentage') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(report, index) of scoreReport"
                    :key="index"
                  >
                    <td>
                      <b>{{ report.firstname+" "+report.lastname }}</b>
                    </td>
                    <td>{{ report.total_passed_count }}</td>
                    <td>{{ report.total_problem_attempt }}</td>
                    <td>{{ report.score_percentage }}</td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard, BOverlay, VBTooltip, BRow, BCol, BButton,
} from 'bootstrap-vue'
import moment from 'moment'
import DateFilter from '@/views/common/components/DateFilter.vue'
import VueApexCharts from 'vue-apexcharts'
import StrengthTable from './StrengthTable.vue'

export default {
  components: {
    BCard,
    BOverlay,
    VueApexCharts,
    BRow,
    BCol,
    StrengthTable,
    BButton,
    DateFilter,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      scoreReport: [],
      lessonScoreReport: [],
      isProcessing: false,
      categories: [],
      scores: [],
      studentLists: [],
      maxDate: moment().format('YYYY-MM-DD'),
      filter: {
        from: null,
        to: null,
      },
    }
  },
  computed: {
    series() {
      return [{
        name: 'series-1',
        data: this.scores,
      }]
    },

    options() {
      return {
        colors: ['#28c76f'],
        chart: {
          id: 'vuechart-example',
        },
        xaxis: {
          categories: this.categories,
        },
      }
    },
  },
  mounted() {
    this.getScoreReport()
  },
  methods: {
    getScoreReport() {
      //   const scoreReport = []
      useJwt.getScoreReport(this.classRoom.id).then(response => {
        this.scoreReport = response.data.data
        this.categories = response.data.data.map(report => `${report.firstname} ${report.lastname}`)
        this.scores = response.data.data.map(report => report.score_percentage)
      })
    },
    getTotalLessonDone(userId) {
      return this.lessonScoreReport.filter(r => r.user_id === userId).length
    },
    getMinMax(array, key) {
      const items = array.map(item => Number(item[key]))
      const max = Math.max(...items)
      const min = Math.min(...items)
      return [{
        title: 'Problem Solving',
        strongest: array.filter(item => Number(item[key]) === max).map(item => ({ ...item, fullName: `${item.firstname} ${item.lastname}` })),
        weakest: array.filter(item => Number(item[key]) === min).map(item => ({ ...item, fullName: `${item.firstname} ${item.lastname}` })),
      }]
    },
    getToolTipContent(lessonInfo) {
      return `<p>Round:${lessonInfo ? lessonInfo.round : 0}</p>
                <p>Points: ${lessonInfo ? lessonInfo.points : 0}</p>
                <p>Cefr Rate: ${lessonInfo ? lessonInfo.cefr_rate : 0}</p>
                <p class='text-white'>Problems Done: ${lessonInfo ? (`${lessonInfo.total_passed_count}/${lessonInfo.total_problems_count}`) : 0}</p>`
    },
    exportPdf() {
      const element = document.getElementById('report-card')
      this.printPdf(element, 'problem-solving-chart.pdf', true)
    },
    getProblemSolvingByDate(date) {
      useJwt.getScoreReport(this.classRoom.id, {
        params: {
          from: date.from,
          to: date.to,
        },
      }).then(response => {
        this.scoreReport = response.data.data
      })
    },
  },
}
</script>

<style scoped>
  .vertical-text {
    writing-mode: tb-rl;
    -webkit-transform: rotate(180deg);
    text-align: center;
    vertical-align: middle;
  }

</style>
