<template>
  <div>
    <vue-apex-charts
      :key="Math.random()"
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    response: {
      type: [Object, Array],
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'by_level',
    },
    colors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    // const self = this
    return {
      chartOptions: {
        ...(this.colors.length > 0 ? { colors: this.colors } : {}),
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          categories: this.categories,
        },
        legend: {
          position: 'right',
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    }
  },
  computed: {
    series() {
      const { response } = this
      if (this.type === 'by_level') {
        return this.getChartByLangLevel(response)
      }
      return this.getChartBySkill(response)
    },
  },
  methods: {
    getChartBySkill(response) {
      const requiredData = [
        {
          name: 'A1',
          key: 'A1',
          data: [0, 0, 0, 0],
        },
        {
          name: 'A2',
          key: 'A2',
          data: [0, 0, 0, 0],
        },
        {
          name: 'B1',
          key: 'B1',
          data: [0, 0, 0, 0],
        },
        {
          name: 'B2',
          key: 'B2',
          data: [0, 0, 0, 0],
        },
      ]
      const availableLevel = this.categories
      response.forEach(report => {
        const level = report.lang_level
        const skillIndex = requiredData.findIndex(value => value.key === level)
        const levelIndex = availableLevel.indexOf(report.skill_type)

        if (levelIndex >= 0 && skillIndex >= 0) {
          const { data } = requiredData[skillIndex]
          // eslint-disable-next-line operator-assignment
          data[levelIndex] = data[levelIndex] + 1
          requiredData[skillIndex].data = data
        }
      })
      return requiredData
    },
    getChartByLangLevel(response) {
      const requiredData = [
        {
          name: 'Reading',
          key: 'reading',
          data: [0, 0, 0, 0],
        },
        {
          name: 'Writing',
          key: 'writing',
          data: [0, 0, 0, 0],
        },
        {
          name: 'Listening',
          key: 'listening',
          data: [0, 0, 0, 0],
        },
        {
          name: 'Speaking',
          key: 'speaking',
          data: [0, 0, 0, 0],
        },
      ]
      const availableLevel = this.categories
      response.forEach(report => {
        const level = report.lang_level
        const levelIndex = availableLevel.indexOf(level)
        const skillIndex = requiredData.findIndex(value => value.key === report.skill_type)
        if (levelIndex >= 0 && skillIndex >= 0) {
          const { data } = requiredData[skillIndex]
          // eslint-disable-next-line operator-assignment
          data[levelIndex] = data[levelIndex] + 1
          requiredData[skillIndex].data = data
        }
      })
      return requiredData
    },
  },
}
</script>
